<router>
{
    name: 'Flow Analytics',
}
</router>
<template lang="pug">

v-container(class="flow-analytics-main").mt-8
    v-row(v-if="isInitialized", justify='center')
        v-col.d-flex.flex-wrap
            .text-h4.brand--text Flow Analytics
    v-row(v-if="isInitialized")
        v-col(justify='left', cols='8')
            FlowChart(
                :seriesData="charts['Today'].seriesData",
                :name="charts['Today'].name",
                :productionVolume="charts['Today'].productionVolume",
                :productionTime="charts['Today'].productionTime",
                :startDate="chartDate(charts['Today'].startDate)"
            )
        v-spacer
        v-col(cols='3')
            v-card(light)
                v-card-title "Times to Meet Demand"
                v-card-text
    v-row(v-if="isInitialized")
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['2 Days Ago'].seriesData",
                :name="charts['2 Days Ago'].name"
                :productionVolume="charts['2 Days Ago'].productionVolume",
                :productionTime="charts['2 Days Ago'].productionTime",
                :startDate="chartDate(charts['2 Days Ago'].startDate)",
                :isHistorical="true"
            )
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['Yesterday'].seriesData",
                :name="charts['Yesterday'].name",
                :productionVolume="charts['Yesterday'].productionVolume",
                :productionTime="charts['Yesterday'].productionTime",
                :startDate="chartDate(charts['Yesterday'].startDate)",
                :isHistorical="true"
            )
    v-row(v-if="isInitialized")
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['Tomorrow'].seriesData",
                :name="charts['Tomorrow'].name",
                :productionVolume="charts['Tomorrow'].productionVolume",
                :productionTime="charts['Tomorrow'].productionTime"
                :startDate="chartDate(charts['Tomorrow'].startDate)"
            )
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['2 Days From Today'].seriesData",
                :name="charts['2 Days From Today'].name",
                :productionVolume="charts['2 Days From Today'].productionVolume",
                :productionTime="charts['2 Days From Today'].productionTime",
                :startDate="chartDate(charts['2 Days From Today'].startDate)"
            )
    v-row(v-if="isInitialized")
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['3 Days From Today'].seriesData",
                :name="charts['3 Days From Today'].name",
                :productionVolume="charts['3 Days From Today'].productionVolume",
                :productionTime="charts['3 Days From Today'].productionTime",
                :startDate="chartDate(charts['3 Days From Today'].startDate)"
            )
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['4 Days From Today'].seriesData",
                :name="charts['4 Days From Today'].name",
                :productionVolume="charts['4 Days From Today'].productionVolume",
                :productionTime="charts['4 Days From Today'].productionTime",
                :startDate="chartDate(charts['4 Days From Today'].startDate)"
            )


</template>

<script lang="js">

import FlowChart from '@/components/portal/analytics/FlowChart';
import moment from 'moment';

export default {
    // extends: dashboard,
    components: {
        FlowChart,
    },
    emits: ['getFlowData'],
    data () {
        return {
            charts: {},
            selectedCustomer: null,
            chartNames: [
                '2 Days Ago', 'Yesterday', 'Today', 'Tomorrow', '2 Days From Today', '3 Days From Today', '4 Days From Today'
            ],
            dataSources: [
                'Production', 'Demand', 'Storage', 'Consumption'
            ],
            customerId: null,
            isFetching: false,
            isInitialized: false,
        };
    },
    watch: {
        customerId: {
            handler (newVal, oldVal) {
                if (newVal == oldVal) return;
                this.getData();
            },
            immediate: true,
        },
        '$store.state.customerId': {
            handler (newVal, oldVal) {
                console.log('Watcher triggered ($store.state.customerId):', oldVal, ' -> ', newVal);
                if (oldVal && newVal && newVal != oldVal) {
                    this.siteLatitude = newVal.site_latitude !== undefined ? newVal.site_latitude : 0;
                    this.siteLongitude = newVal.site_longitude !== undefined ? newVal.site_longitude : 0;
                    this.productionMax = newVal.production_max !== undefined ? newVal.production_max : 0;
                    this.storageMax = newVal.storage_max !== undefined ? newVal.storage_max : 0;
                    this.storageMin = newVal.storage_max !== undefined ? newVal.storage_min : 0;
                    this.plantStartTime = newVal.plant_start_time !== undefined ? newVal.plant_start_time : '12:00';

                    // Optionally re-validate the form
                    this.$nextTick(() => {
                        this.reset();
                        this.$router.push('/customer/analytics');
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        currentCustomerId () {
            return this.$store.state.customerId;
        },

    },

    methods: {
        init () {
            if(!this.$store.state.customerId){
                this.$router.push('/customer/analytics');
                return;
            }
            this.getData();
        },

        reset () {
            this.isInitialized = false;
            this.charts = {};
            this.customerId = null;
            this.isFetching = false;
        },

        getData () {
            // fetch data from API or websocket
            if (this.isFetching) return;
            this.isFetching = true;
            this.customerId = this.currentCustomerId;
            console.log('getData fired',this.customerId);
            const now = moment.utc('2024-02-09', 'YYYY-MM-DD');
            this.socketEmit('getFlowData', { customerId:  this.customerId, dateStart: now.format('YYYY-MM-DD')  }, result => {
                this.isFetching = false;
                if(result.error){
                    console.error('Failed to fetch data:', result.error);
                    return;
                }
                console.log('Flow data:', result.data);
                result.data.forEach(chart => {
                    // loop through the chart.seriesData to apply the yAxis and step properties
                    chart.seriesData.forEach(series => {
                        if (series.name === 'storage') {
                            series.yAxis = 1;
                        } else if (series.name === 'consumption') {
                            series.yAxis = 1;
                            series.visible = false;
                        } else if (series.name === 'production') {
                            series.yAxis = 0;
                            series.step = 'center';
                        } else {
                            series.yAxis = 0;
                        }
                    });
                    this.$set(
                        this.charts,
                        chart.name,
                        chart
                    );
                });
                this.isInitialized = true;
            });

        },
        chartDate (strDate) {
            const dt = moment.utc(strDate, 'YYYY-MM-DD');
            const dtNumeric = dt.format('YYYY-MM-DD');
            const dtDay = dt.format('dddd');
            return `<b>${dtNumeric} &raquo; <b>${dtDay}</b>`;
        }
    },
    mounted () {
        this.init();
        console.log('FlowAnalytics mounted');
    },
    created () {
        this.$store.dispatch('connectSocket');
    }
};
</script>

<style scoped>
    .flow-analytics-main {
        max-width: 90%;
    }
</style>
